import About from "../../components/landing/About";
import Features from "../../components/landing/Features";
import Pricing from "../../components/landing/Pricing";
import Headerlanding from "../../components/global/Headerlanding";
import Footer from "../../components/global/Footer";
import Benefits from "../../components/landing/Benefits";
import Charity from "../../components/landing/Charity";
import Gemini from "../../components/landing/Gemini";

const Landing = (props) => {
  return (
    <>
      <div id="top" className="element">
        <Headerlanding />
      </div>

      {/* <div id="feature" className="element">
        <Features />
      </div> */}
      <div id="about" className="element">
        <About />
      </div>
      <div id="features" className="element">
        <Features />
      </div>
      <div id="gemini" className="element">
        <Gemini />
      </div>
      <div id="benefits" className="element">
        <Benefits />
      </div>
      {/* <div id="industries" className="element">
        <Industries />
      </div> */}
      {/* <div id="pricing-free" className="element">
        <FreePricingSection />
      </div> */}
      <div id="pricing" className="element">
        <Pricing />
      </div>
      {/* <div id="blog" className="element">
        <BlogSection />
      </div> */}
      <div id="contact" className="element">
        <Charity />
      </div>
      {/* <RequestDemo /> */}
      <Footer />
    </>
  );
};

export default Landing;
