import React from "react";
import Navbar from "./Navbar";
import iosbtn from "../../assets/img/Landing page-slicing/Download-App-Store.png";
import androidbtn from "../../assets/img/Landing page-slicing/Download-Play-Store.png";
import Lottie from "react-lottie";
import Homepage from "../../assets/img/lotties/Global-Communication.json";
import { Button, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";

const Headerlanding = (props) => {
  return (
    <header className="header  position-relative">
      <Navbar />
      <Row
        md="2"
        xs="1"
        className="f-row position-relative justify-content-around"
      >
        <Col sm="7" className="flex-fill mt-4 pl-4 pr-0" style={{ maxWidth: "auto" }}>
          <div className="f-coll ml-2 pl-3">
            <h1 className="font-family-3 mb-0" style={{ fontSize: "36px" }}>
              Mobile Team Communication
              <br />
              Powered by Google Gemini Al
            </h1>
            <p className="my-4">
              Merk is a simple and secure communication app for field workers
              and mobile teams offering text messaging, audio calling, video
              calling, and push to talk, for users to communicate individually
              or in groups. We integrated the Google Gemini AI for seamless
              assistance and text based chat. Designed for internal use only, we
              keep your mobile team connected and efficient at all times.
            </p>
            <div className="  pl-0 " style={{ display: "flex" }}>
              <Link to="" className="header-btn">
                <img src={iosbtn} />
              </Link>
              <Link to="" className="header-btn">
                <img src={androidbtn} />
              </Link>
            </div>
          </div>
        </Col>
        <Col className="d-flex align-items-center pb-5 justify-content-around">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: Homepage,
            }}
            style={{ marginBottom: "6rem" }}
            height={250}
            width={250}
          />
          {/* <img src={HomeBannerSide} alt="banner side" width='400px' style={{ marginBottom: '10rem', marginRight: '25%' }} /> */}
        </Col>
      </Row>
      <div>
        <div className="wave-section-separator">
          <svg viewBox="0 0 120 28">
            <defs>
              <mask id="xxx">
                <circle cx="7" cy="12" r="40" fill="#fff" />
              </mask>

              <filter id="goo">
                <feGaussianBlur
                  in="SourceGraphic"
                  stdDeviation="2"
                  result="blur"
                />
                <feColorMatrix
                  in="blur"
                  mode="matrix"
                  values="
           1 0 0 0 0  
           0 1 0 0 0  
           0 0 1 0 0  
           0 0 0 13 -9"
                  result="goo"
                />
                <feBlend in="SourceGraphic" in2="goo" />
              </filter>
              <path
                id="wave"
                d="M 0,10 C 30,10 30,15 60,15 90,15 90,10 120,10 150,10 150,15 180,15 210,15 210,10 240,10 v 28 h -240 z"
              />
            </defs>

            <use id="wave3" className="wave" href="#wave" x="0" y="4"></use>
            <use id="wave2" className="wave" href="#wave" x="0" y="6"></use>
            <g className="gooeff">
              {/* <circle className="drop drop1" cx="20" cy="2" r="1.8"  />
  <circle className="drop drop2" cx="25" cy="2.5" r="1.5"  />
  <circle className="drop drop3" cx="16" cy="2.8" r="1.2"  /> */}
              <use id="wave1" className="wave" href="#wave" x="0" y="7" />

              {/* <g mask="url(#xxx)">
    <path   id="wave1"  className="wave" d="M 0,10 C 30,10 30,15 60,15 90,15 90,10 120,10 150,10 150,15 180,15 210,15 210,10 240,10 v 28 h -240 z" />
    </g> */}
            </g>
          </svg>
        </div>
      </div>
    </header>
  );
};

export default Headerlanding;
