import React, { Component } from "react";
import Lottie from "react-lottie";
import {
  Card,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Col,
  Row,
} from "reactstrap";
import contact from "../../assets/img/lotties/Contact.json";

class Contact extends Component {
  render() {
    return (
      <div className="Contact bg-white">
        <Row
          className="align-items-center w-100 justify-content-center"
          style={{ maxWidth: "1400px" }}
        >
          <Col
            xs="12"
            sm="4"
            md="5"
            lg="5"
            className="text-left contactImg px-4"
          >
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: contact,
                rendererSettings: {
                  preserveAspectRatio: "xMinYMid meet",
                },
              }}
            />
            {/* <img src={ETE} className="eteImg" /> */}
          </Col>
          <Col xs="12" sm="8" md="7" lg="5" className="px-4">
            <h1 className="font-family-2">Contact Us</h1>

            <Card>
              <Form>
                <FormGroup>
                  <Input placeholder="Your Name" name="name" type="name" />{" "}
                </FormGroup>
                <FormGroup>
                  <Input placeholder="Company Name" name="name" type="name" />{" "}
                </FormGroup>

                <FormGroup>
                  <Input
                    type="number"
                    name="number"
                    placeholder="Number of Employees"
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    type="number"
                    name="number"
                    placeholder="Phone Number"
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    type="email"
                    name="email"
                    placeholder="Email Address"
                  />
                </FormGroup>
                <Button color="site-secondary" className="w-50">
                  Submit
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
export default Contact;
